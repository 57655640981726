<template>
  <div class="aali_tm_section" id="about">
    <div class="aali_tm_about">
      <div class="about_inner">
        <div class="left">
          <div class="image">
            <img src="img/thumbs/45-49.jpg" alt="" />
            <div class="main" data-img-url="img/about/1.png"></div>
            <span
              class="square moving_effect"
              data-direction="y"
              data-reverse="yes"
            ></span>
            <span class="border"
              ><span
                class="in moving_effect"
                data-direction="x"
                data-reverse="yes"
              ></span
            ></span>
          </div>
        </div>
        <div class="center">
          <div class="title wow fadeInUp" data-wow-duration="1s">
            <span class="small">Über mich</span>
            <h3>Ich bin <span>Hakan</span></h3>
            <h3>App | Web -<span>Entwickler</span></h3>
            <span class="subtitle">Ansässig in der Hansestadt Lübeck, DE</span>
          </div>
          <div
            class="text wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <p>
              Hi! Mein Name ist Hakan Sisman. Ich bin App & Web Entwickler und gehe sehr leidenschaftlich und engagiert meiner Berufung nach.
            </p>
            <p>
              Mit über 5 Jahren Erfahrung als professioneller Software- , App- und Webentwickler habe ich die notwendigen Fähigkeiten und Kenntnisse, um Ihr Projekt zum Erfolg zu führen.
            </p>
          </div>
          <div
            class="aali_tm_button wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="0.2s"
          >
            <a href="img/cv/1.jpg" download>
              <span v-html="download"> </span
            ></a>
          </div>
        </div>
        <div class="right">
          <div class="image">
            <img src="img/thumbs/41-61.jpg" alt="" />
            <div class="main" data-img-url="img/about/2.jpg"></div>
            <div
              class="square moving_effect"
              data-direction="x"
              data-reverse="no"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { download } from "./svgImage";
export default {
  name: "About",
  data() {
    return {
      download: `Download CV ${download}`,
    };
  },
};
</script>
