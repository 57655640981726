<template>
  <div class="aali_tm_section">
    <div class="aali_tm_resume">
      <div class="content">
        <div class="container">
          <div class="resume_in">
            <div
              class="aali_tm_main_title"
              data-text-align="left"
              data-color="dark"
            >
              <span>Mehr erfahren</span>
              <h3>Mein Lebenslauf</h3>
              <p>
                Ich schätze jeden Schritt des Planungsprozesses, von der Diskussion und Zusammenarbeit bis zum Konzept und der Ausführung.
              </p>
            </div>
            <div class="content_inner">
              <ul>
                <li class="wow fadeInLeft" data-wow-duration="1s">
                  <h3 class="main_title"><span>Bildung</span></h3>
                  <ul class="list">
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="edu"></span>
                        <div class="info">
                          <div class="left">
                            <h3>B.Sc. Wirtschaftsingenieur</h3>
                            <span>Technische Hochschule Lübeck</span>
                          </div>
                          <div class="right">
                            <span>2014 - 2018</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Aali is a leading web design agency with an
                            award-winning design
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="edu"></span>
                        <div class="info">
                          <div class="left">
                            <h3>St. geprüfter Betriebswirt</h3>
                            <span>Carl-Friedrich-von-Rumohr</span>
                          </div>
                          <div class="right">
                            <span>2012 - 2014</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Aali is a leading web design agency with an
                            award-winning design
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="edu"></span>
                        <div class="info">
                          <div class="left">
                            <h3>St. geprüfter Fachwirt</h3>
                            <span>Carl-Friedrich-von-Rumohr</span>
                          </div>
                          <div class="right">
                            <span>2012 - 2013</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Aali is a leading web design agency with an
                            award-winning design
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="wow fadeInRight" data-wow-duration="1s">
                  <h3 class="main_title"><span>Experience</span></h3>
                  <ul class="list">
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>IT-Consulting & Softwareentwicklung</h3>
                            <span>Aikonetic GmbH</span>
                          </div>
                          <div class="right">
                            <span>Seit 2019</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Aali is a leading web design agency with an
                            award-winning design
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>Bachelorthesis Digitale Prozessoptimierung</h3>
                            <span>Nordic Solar</span>
                          </div>
                          <div class="right">
                            <span>2019</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Aali is a leading web design agency with an
                            award-winning design
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>Technischer Einkauf & Anlagenplanung</h3>
                            <span>Nordic Solar</span>
                          </div>
                          <div class="right">
                            <span>2017 - 2018</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Aali is a leading web design agency with an
                            award-winning design
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="media" data-background-style="video">
        <!-- Background Styles: "video" and "image" // Also you can use any youtube, vimeo, and local videos -->
        <div
          class="video jarallax"
          data-speed="1"
          data-jarallax-video="https://www.youtube.com/watch?v=PUHw9OLkBMU"
        ></div>
        <div
          class="image jarallax"
          data-speed="0"
          data-img-url="img/about/2.jpg"
        ></div>
        <span
          class="square moving_effect"
          data-direction="y"
          data-reverse="yes"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { jarallaxContent } from "../utils/utils";
import { edu, exp } from "./svgImage";

export default {
  name: "Resume",
  data() {
    return {
      edu,
      exp,
    };
  },
  mounted() {
    jarallaxContent();
  },
};
</script>
