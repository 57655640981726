var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aali_tm_section"},[_c('div',{staticClass:"aali_tm_resume"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"resume_in"},[_vm._m(0),_c('div',{staticClass:"content_inner"},[_c('ul',[_c('li',{staticClass:"wow fadeInLeft",attrs:{"data-wow-duration":"1s"}},[_vm._m(1),_c('ul',{staticClass:"list"},[_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.edu)}}),_vm._m(2),_vm._m(3)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.edu)}}),_vm._m(4),_vm._m(5)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.edu)}}),_vm._m(6),_vm._m(7)])])])]),_c('li',{staticClass:"wow fadeInRight",attrs:{"data-wow-duration":"1s"}},[_vm._m(8),_c('ul',{staticClass:"list"},[_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.exp)}}),_vm._m(9),_vm._m(10)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.exp)}}),_vm._m(11),_vm._m(12)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.exp)}}),_vm._m(13),_vm._m(14)])])])])])])])])]),_vm._m(15)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aali_tm_main_title",attrs:{"data-text-align":"left","data-color":"dark"}},[_c('span',[_vm._v("Mehr erfahren")]),_c('h3',[_vm._v("Mein Lebenslauf")]),_c('p',[_vm._v(" Ich schätze jeden Schritt des Planungsprozesses, von der Diskussion und Zusammenarbeit bis zum Konzept und der Ausführung. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"main_title"},[_c('span',[_vm._v("Bildung")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("B.Sc. Wirtschaftsingenieur")]),_c('span',[_vm._v("Technische Hochschule Lübeck")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2014 - 2018")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("St. geprüfter Betriebswirt")]),_c('span',[_vm._v("Carl-Friedrich-von-Rumohr")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2012 - 2014")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("St. geprüfter Fachwirt")]),_c('span',[_vm._v("Carl-Friedrich-von-Rumohr")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2012 - 2013")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"main_title"},[_c('span',[_vm._v("Experience")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("IT-Consulting & Softwareentwicklung")]),_c('span',[_vm._v("Aikonetic GmbH")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("Seit 2019")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("Bachelorthesis Digitale Prozessoptimierung")]),_c('span',[_vm._v("Nordic Solar")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2019")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("Technischer Einkauf & Anlagenplanung")]),_c('span',[_vm._v("Nordic Solar")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2017 - 2018")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media",attrs:{"data-background-style":"video"}},[_c('div',{staticClass:"video jarallax",attrs:{"data-speed":"1","data-jarallax-video":"https://www.youtube.com/watch?v=PUHw9OLkBMU"}}),_c('div',{staticClass:"image jarallax",attrs:{"data-speed":"0","data-img-url":"img/about/2.jpg"}}),_c('span',{staticClass:"square moving_effect",attrs:{"data-direction":"y","data-reverse":"yes"}})])
}]

export { render, staticRenderFns }