<template>
  <div class="aali_tm_section" id="contact">
    <div class="aali_tm_contact">
      <div class="container">
        <div
          class="aali_tm_main_title"
          data-text-align="center"
          data-color="dark"
        >
          <span>Kontaktieren Sie mich</span>
          <h3>Einfach das Formular verwenden</h3>
        </div>
        <div class="contact_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <div class="text">
              <p>
                Bitte füllen Sie das Formular aus, um mit mir Kontakt aufzunehmen.
                Oder rufen Sie mich zwischen von Montag bis Freitag von 9:00 bis 19:00 Uhr an.
              </p>
            </div>
            <ul>
              <li>
                <div
                  class="list_inner"
                  v-html="
                  call +
                    `<span>Jetzt anrufen</span>
                  <h3>
                    <a href='tel:+4915792575626'
                      >+4915792575626</a
                    >
                  </h3>`
                  "
                ></div>
              </li>
              <li>
                <div
                  class="list_inner"
                  v-html="
                    msg +
                    `<span>Email</span>
                  <h3>
                    <a class='line_anim' href='mailto:hallo@hakan-sisman.de'
                      >hallo@hakan-sisman.de</a
                    >
                  </h3>`
                  "
                ></div>
              </li>
            </ul>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <div class="fields">
              <form
                class="contact_form"
                id="contact_form"
                @submit.prevent="handleSubmit"
              >
                <div
                  class="returnmessage"
                  data-success="Ihre Nachricht ist eingegangen und Sie erhalten eine Bestätigung. Ich werde mich in Kürze mit Ihnen in Verbindung setzen."
                  :style="{
                    display: success ? 'block' : 'none',
                  }"
                >
                  <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  <span class="contact_success">
                    Ihre Nachricht wird versendet und Sie erhalten eine Bestätigung. Ich werde mich in Kürze mit Ihnen in Verbindung setzen.
                  </span>
                </div>
                <div
                  class="empty_notice"
                  :style="{ display: error ? 'block' : 'none' }"
                >
                  <span>Bitte füllen Sie die erforderlichen Felder aus</span>
                </div>
                <div class="input_list">
                  <ul>
                    <li>
                      <input
                        id="name"
                        type="name"
                        v-model="fromData.name"
                        placeholder="Name"
                      />
                    </li>
                    <li>
                      <input
                        id="email"
                        type="mail"
                        v-model="fromData.email"
                        placeholder="Email"
                      />
                    </li>
                    <li>
                      <input
                        id="phone"
                        type="tel"
                        placeholder="Telefon"
                        name="phn"
                        v-model="fromData.phone"
                      />
                    </li>
                  </ul>
                </div>
                <div class="message_area">
                  <textarea
                    id="message"
                    placeholder="Nachricht"
                    v-model="fromData.msg"
                  ></textarea>
                </div>
                <div style="margin-bottom: 10px">
                <input type="checkbox" id="checkbox" v-model="fromData.accept" />
                <label for="checkbox">Ich akzeptiere die Datenschutzbestimmungen.</label>
                </div>
                <div class="aali_tm_button">
                  <a id="send_message" href="#" @click.prevent="handleSubmit"
                    ><span v-html="'Nachricht senden' + msgSent"> </span
                  ></a>
                </div>

                <!-- If you want change mail address to yours, just open "modal" folder >> contact.php and go to line 4 and change detail to yours.  -->
              </form>
            </div>
          </div>
        </div>
      </div>
      <span
        class="square moving_effect"
        data-direction="y"
        data-reverse="yes"
      ></span>
    </div>
  </div>
</template>

<script>
import { call, msg, msgSent } from "./svgImage";
import axios from 'axios';
export default {
  name: "Contact",
  data() {
    return {
      msgSent,
      call,
      msg,
      fromData: {
        name: "",
        email: "",
        phone: "",
        msg: "",
        accept: false,
      },
      error: false,
      success: false,
    };
  },
  methods: {
    handleSubmit() {
      const { name, email, phone, msg, accept } = this.fromData;
      if (name && email && phone && msg && accept) {
        this.success = true;
        const baseURI = 'https://api.aikonetic.com/api/contactMail'
        axios.post(baseURI, {
          name: this.fromData.name,
          email: this.fromData.email,
          messagee: this.fromData.msg,
          val: this.accept ? 'Datenschutzbestimmung akzeptiert' : 'Datenschutzbestimmennichtakzeptiert',
          phonenumber: this.fromData.phone
        }).then(response => {
          console.log(response)
          this.name = ''
          this.msg = ''
          this.accept = false
          this.phone = ''
          this.email = ''
        }).finally(() => {
          this.accept = false;

        })
        setTimeout(() => {
          this.success = false;
          this.fromData.name = "";
          this.fromData.msg = "";
          this.fromData.accept = false
          this.fromData.phone = "";
          this.fromData.email = "";
        }, 5000);
      } else {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 2000);
      }
    },
  },
};
</script>
<style>
.lds-spinner {
  color: rgba(255, 168, 65, 0.9);
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgba(253, 183, 96, 0.9);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
